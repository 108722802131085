import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./FiscalDeviceScreen.module.scss";
import classNames from "classnames";
import { CloseIcon } from "../../../assets";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSigngleFiscalDevice } from "../../../store/actions/admin/fiscalDevice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import DoubleInvoicesModal from "../../../components/modals/doubleInvoicesModal/DoubleInvoicesModal";
import SecondaryButton from "../../../components/secondaryButton/SecondaryButton";
import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import FiscalDeviceInfoScreen from "./fiscalDeviceInfoScreen/FiscalDeviceInfoScreen";
import FiscalDeviceLogsScreen from "./fiscalDeviceLogsScreen/FiscalDeviceLogsScreen";
import FiscalDeviceNotesScreen from "./fiscalDeviceNotesScreen/FiscalDeviceNotesScreen";

const FiscalDeviceScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const LINKS = [
    { name: "Info", to: `info` },
    { name: "Logovi", to: `logs` },
    { name: "Napomene", to: `notes` },
  ];

  const [doubleInvoicesModalOpen, setDoubleInvoicesModalOpen] = useState(false);
  const fiscalDevice = useSelector(
    (state) => state.fiscalDevices.singleFiscalDevice
  );

  const history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!fiscalDevice?.data || +fiscalDevice?.data?.id !== +id) {
      dispatch(fetchSigngleFiscalDevice(id));
    }
  }, []);

  const title = useMemo(() => {
    const maxTitleLen = isMobile ? 10 : 55;
    return (
      <div className={styles.titleWrap}>
        <div className={styles.closeIconWrap}>
          <img
            src={CloseIcon}
            alt="close"
            onClick={() => {
              history.push("/fiscal-devices");
            }}
            className={styles.closeIcon}
          />
        </div>
        <p className={isMobile ? styles.titleMobile : styles.title}>
          {`Fiskalni uredjaj ${
            fiscalDevice?.data?.name?.length > maxTitleLen
              ? fiscalDevice?.data?.name.substring(0, maxTitleLen) + "..."
              : fiscalDevice?.data?.name
          }`}
        </p>
      </div>
    );
  }, [fiscalDevice?.data, id]);

  return (
    <DashboardLayout>
      <DoubleInvoicesModal
        modalOpen={doubleInvoicesModalOpen}
        onClose={() => {
          setDoubleInvoicesModalOpen(false);
        }}
        fiscalDeviceId={id}
      />
      <div className={styles.content}>
        <header className={styles.headerNav}>
          <div className={styles.header}>
            {title}
            <div className={styles.headerBtnsWrap}>
              <SecondaryButton
                name={"Analitika"}
                className={styles.headerBtn}
                onClick={() => {
                  history.push("/analytics/" + fiscalDevice.data.id);
                }}
              />
              <div className={styles.headerBtn}>
                <SecondaryButton
                  name={"Prikaži duple račune"}
                  className={styles.headerBtn}
                  onClick={() => setDoubleInvoicesModalOpen(true)}
                />
              </div>
              <div className={styles.headerBtn}>
                <SecondaryButton
                  name={"Sinhronizacija računa"}
                  className={styles.headerBtn}
                  onClick={() => {
                    fiscalDeviceService.syncInvoices(
                      fiscalDevice.data.id,
                      moment()
                        .set("month", 8)
                        .set("date", 1)
                        .set("year", 2022)
                        .tz("Europe/Belgrade")
                        .format("x")
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.navWrap}>
            {LINKS.map((link) => {
              return (
                <Link
                  to={{ pathname: `${url}/${link.to}` }}
                  className={classNames(
                    pathname.includes(link.to) ? styles.selected : styles.link
                  )}
                >
                  <p>{link.name}</p>
                </Link>
              );
            })}
          </div>
        </header>

        <Switch>
          <Route path={`${url}/info`}>
            <FiscalDeviceInfoScreen id={id} />
          </Route>
          <Route path={`${url}/logs`}>
            <FiscalDeviceLogsScreen id={id} />
          </Route>
          <Route path={`${url}/notes`}>
            <FiscalDeviceNotesScreen id={id} />
          </Route>
          <Route exact path={`${url}`}>
            <Redirect to={`${url}/info`} />
          </Route>
        </Switch>
      </div>
    </DashboardLayout>
  );
};

export default FiscalDeviceScreen;
