import axios from "axios";
import jwt_decode from "jwt-decode";
import config from "../config";
import authService from "./services/authService";
import {
  LOGIN_URL,
  REFRESH_TOKEN,
  REQUEST_PASSWORD_RESET,
  RESEND_CODE,
  SIGN_UP_URL,
  UPDATE_PASSWORD,
  VERIFY_EMAIL,
  VERIFY_PASSWORD_RESET_CODE,
} from "./urls";

const axiosApiClient = axios.create({
  baseURL: `${config.api.baseUrl}`,
});

axiosApiClient.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("AUTH_TOKEN");
    if (accessToken) {
      if (
        !config.url?.includes("refresh-token") &&
        isTokenExpiringInNext60Seconds(accessToken)
      ) {
        // get new token
        const { data } = await authService.refreshToken();

        accessToken = data.data.accessToken;
        localStorage.setItem("AUTH_TOKEN", accessToken);
      }

      if (
        !config.url?.includes(LOGIN_URL) &&
        !config.url?.includes(SIGN_UP_URL) &&
        !config.url?.includes(REFRESH_TOKEN) &&
        !config.url?.includes(VERIFY_EMAIL) &&
        !config.url?.includes(RESEND_CODE) &&
        !config.url?.includes(REQUEST_PASSWORD_RESET) &&
        !config.url?.includes(VERIFY_PASSWORD_RESET_CODE) &&
        !config.url?.includes(UPDATE_PASSWORD)
      ) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

const isTokenExpiringInNext60Seconds = (token) => {
  try {
    const decoded = jwt_decode(token);
    if (!decoded || !decoded.exp) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = decoded.exp - currentTime;
    return timeLeft <= 60;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

export default axiosApiClient;
