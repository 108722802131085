import apiBase from "../api";
import {
  LOGIN_URL,
  FETCH_USER_INFO_URL,
  SIGN_UP_URL,
  COMPANY_INFORMATION,
  REFRESH_TOKEN,
  VERIFY_EMAIL,
  RESEND_CODE,
  REQUEST_PASSWORD_RESET,
  VERIFY_PASSWORD_RESET_CODE,
  UPDATE_PASSWORD,
} from "../urls";
import { handleError } from "../../util/errors";

class AuthService {
  fetchUserInfo = async () => {
    try {
      const response = await apiBase.get(FETCH_USER_INFO_URL);
      if (response.status !== 200) throw new Error();
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, "Error occured while fetching user");
    }
  };

  login = async (credentials) => {
    try {
      const response = await apiBase.post(LOGIN_URL, credentials, {
        withCredentials: true,
      });
      if (response.status !== 201) throw new Error();
      const resp = response.data.data;
      return resp;
    } catch (err) {
      throw handleError(err, "Error occured while logging in");
    }
  };

  refreshToken = async () => {
    try {
      return await apiBase.post(
        REFRESH_TOKEN,
        {},
        { withCredentials: true }
      );
    } catch (err) {
      throw handleError(err, "Error occured while refreshing token");
    }
  };

  signUp = async (data) => {
    try {
      const response = await apiBase.post(SIGN_UP_URL, data);
      if (response.status !== 201) throw new Error();
    } catch (err) {
      throw handleError(err, "Error occured while signing up");
    }
  };

  postCompanyInformation = async (data) => {
    try {
      const response = await apiBase.post(COMPANY_INFORMATION, data);
      if (response.status !== 201) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error creating company");
    }
  };

  verifyEmail = async (data) => {
    try {
      const response = await apiBase.post(VERIFY_EMAIL, data);
      if (response.status !== 200) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error verifying email");
    }
  };

  resendCode = async (data) => {
    try {
      const response = await apiBase.post(RESEND_CODE, data);
      if (response.status !== 201) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error resending code");
    }
  };

  requestPasswordReset = async (data) => {
    try {
      const response = await apiBase.post(REQUEST_PASSWORD_RESET, data);
      if (response.status !== 201) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error ");
    }
  };

  verifyPasswordResetCode = async (data) => {
    try {
      const response = await apiBase.post(VERIFY_PASSWORD_RESET_CODE, data);
      if (response.status !== 200) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error verifying code");
    }
  };

  updatePassword = async (data) => {
    try {
      const response = await apiBase.post(UPDATE_PASSWORD, data);
      if (response.status !== 201) throw new Error();
      return response.data.data;
    } catch (err) {
      throw handleError(err, "Error updating password");
    }
  };
}

export default new AuthService();
