import { Modal } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import noteService from "../../../apis/services/admin/noteService";
import Button from "../../button/Button";
import Input from "../../input/Input";
import styles from "./AddNoteModal.module.scss";

// Send one of these properties, depending on level you want the note to be added to
// entityIds:
// {
//  fiscalDeviceId?: number,
//  companyId?: number,
//  businessUnitId?: number,
// }

const AddNoteModal = ({ modalOpen, onClose, onAdd, entityIds }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await noteService.create({
        title,
        description,
        ...entityIds,
      });

      onAdd();
      toast.success("Note successfully added!");
    } catch (e) {
      setIsLoading(false);
      toast.error("There was an error!");
    }
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <div className={styles.inputWrapper}>
            <p>Naslov</p>
            <Input onChange={setTitle} value={title} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Napomena</p>
            <Input onChange={setDescription} value={description} />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            name={"Sačuvaj"}
            onClick={onSubmit}
            showLoader={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
