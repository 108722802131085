// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    // baseUrl: "https://staging-api.sdf.rs/api",
    // baseUrl: "http://localhost:4000/api",
    // baseUrl: "https://staging-api.sdf.rs",
    // baseUrl: "http://sdf-alb-1154569211.eu-central-1.elb.amazonaws.com/api",
    // baseUrl: "https://server.sdf.rs/api",
    // baseUrl: "https://test-server.sdf.rs/api",
    baseUrl: "https://staging-api.sdf.rs/api",
  },
};
