import { useEffect, useState } from "react";
import Notes from "../../../../components/notes/Notes";
import styles from "./FiscalDeviceNotesScreen.module.scss";
import noteService from "../../../../apis/services/admin/noteService";

const FiscalDeviceNotesScreen = ({ id }) => {
  const [notes, setNotes] = useState();

  useEffect(() => {
    if (id) {
      fetchNotes();
    }
  }, [id]);

  const fetchNotes = async () => {
    const data = await noteService.getAllByFiscalDeviceId(id);

    setNotes(data);
  };

  const handleNoteAdded = async () => {
    await fetchNotes();
  };

  return (
    <div className={styles.content}>
      {/* component for notes */}
      <div className={styles.cardContainer}>
        <Notes
          notes={notes}
          fiscalDeviceId={parseInt(id)}
          onNoteAdded={handleNoteAdded}
        />
      </div>
    </div>
  );
};

export default FiscalDeviceNotesScreen;
