import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import AddNoteModal from "../modals/addNoteModal/AddNoteModal";
import SecondaryButton from "../secondaryButton/SecondaryButton";
import styles from "./Notes.module.scss";

const Notes = ({ notes, onNoteAdded, fiscalDeviceId }) => {
  const [selectedNote, setSelectedNote] = useState();
  const [isAddNewNoteModalOpen, setIsAddNewNoteModalOpen] = useState(false);

  useEffect(() => {
    if (notes && notes.length > 0) {
      setSelectedNote(notes[0]);
    }
  }, [notes]);

  const onNoteClick = (note, e) => {
    setSelectedNote(note);

    e.currentTarget.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const onAddNewNoteModalClose = () => {
    setIsAddNewNoteModalOpen(false);
  };

  const openAddNewNoteModal = () => {
    setIsAddNewNoteModalOpen(true);
  };

  const handleNoteAdded = () => {
    onNoteAdded();
    onAddNewNoteModalClose();
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.left}>
          <div className={styles.leftHeader}>
            <h3>Sve napomene</h3>
            <div>
              <SecondaryButton
                name="Nova napomena"
                onClick={openAddNewNoteModal}
              />
            </div>
          </div>
          <div className={styles.list}>
            {notes ? (
              notes.length > 0 ? (
                notes.map((note) => {
                  return (
                    <div
                      key={note.id}
                      className={classNames(
                        styles.note,
                        selectedNote && selectedNote.id === note.id
                          ? styles.noteActive
                          : ""
                      )}
                      onClick={(e) => onNoteClick(note, e)}
                    >
                      <div className={styles.noteHeader}>
                        <div className={styles.noteAuthor}>
                          {note.createdBy.firstName} {note.createdBy.lastName}
                        </div>
                        <div className={styles.noteDate}>
                          {moment
                            .utc(note.createdAt)
                            .tz(moment.tz.guess())
                            .format("DD-MM-YYYY, HH:mm:ss")}
                        </div>
                      </div>
                      <div className={styles.noteContent}>
                        <div className={styles.noteTitle}>{note.title}</div>
                        <span className={styles.noteDescription}>
                          {note.description}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles.loaderEmptyStateContainer}>
                  <div className={styles.emptyState}>
                    <h3>Nema podataka</h3>
                    <span>Još uvek nema dodatih napomena.</span>
                    <SecondaryButton
                      name="Nova napomena"
                      onClick={openAddNewNoteModal}
                    />
                  </div>
                </div>
              )
            ) : (
              <div className={styles.loaderEmptyStateContainer}>
                <Loader />
              </div>
            )}
          </div>
          <div className={styles.leftFooter}>
            <span>
              Ukupno: <strong>{notes ? notes.length.toString() : "0"}</strong>
            </span>
          </div>
        </div>

        <div className={styles.right}>
          {selectedNote ? (
            <div className={styles.content}>
              <div className={styles.contentHeader}>
                <span className={styles.contentHeaderTitle}>
                  {selectedNote.title}
                </span>
                <div className={styles.additionalInfo}>
                  <span>
                    {selectedNote.createdBy.firstName}{" "}
                    {selectedNote.createdBy.lastName}
                  </span>
                  <span>
                    {moment
                      .utc(selectedNote.createdAt)
                      .tz(moment.tz.guess())
                      .format("DD-MM-YYYY, HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div className={styles.contentBody}>
                {selectedNote.description}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Has to be this ugly because modal does not unmount and all the values are persisted >.< */}
      {isAddNewNoteModalOpen && (
        <AddNoteModal
          entityIds={{ fiscalDeviceId }}
          modalOpen={isAddNewNoteModalOpen}
          onClose={onAddNewNoteModalClose}
          onAdd={handleNoteAdded}
        />
      )}
    </>
  );
};

export default Notes;
