import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { CREATE_NOTE, FETCH_ALL_NOTES_BY_FISCAL_DEVICE_ID } from "../../urls";

class NoteService {
  create = async (note) => {
    try {
      const response = await apiBase.post(CREATE_NOTE, {
        ...note,
      });
      if (response.status !== 201) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error creating note");
    }
  };

  getAllByFiscalDeviceId = async (id) => {
    try {
      const response = await apiBase.get(
        FETCH_ALL_NOTES_BY_FISCAL_DEVICE_ID(id)
      );
      if (response.status !== 200)throw new Error();
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, "Error fetching notes");
    }
  };
}

export default new NoteService();
